<template>
  <a-card :bordered="false" class="card-area">

    <common-table
      ref="table"
      path="firmware"
      :columns="columns"
      >
      <template slot="search">
        <a-button v-hasPermission="['firmware:add']" ghost type="primary" @click="add">新增</a-button>
        <a-button v-hasPermission="['firmware:upgrade']" @click="firmwareUpgrade">固件升级</a-button>
      </template>

      <template slot="header">
        <a-alert style="margin-bottom: 10px" type="warning" message="同一产品只能有一个上架的固件，上架的固件会作为小程序更新时所用的固件" show-icon />
      </template>

      <template slot="type" slot-scope="{record}">
        <a-tag v-if="record.type==0" color="green">WIFI固件</a-tag>
        <a-tag v-else color="blue">MCU固件</a-tag>
      </template>

      <template slot="enable" slot-scope="{record}">
        <a-switch size="small" :checked="record.enable" @click="enableRecord(record)"/>
      </template>

      <template slot="operation" slot-scope="{record}">
        <action-edit v-hasPermission="['firmware:update']" @click="edit(record)"></action-edit>
        <action-delete v-hasPermission="['firmware:delete']" @click="remove(record)"></action-delete>
        <a-badge v-hasNoPermission="['firmware:update','firmware:delete']" status="warning" text="无权限"></a-badge>
      </template>

    </common-table>

    <firmware-edit ref="firmwareEdit" @success="getList"></firmware-edit>
  </a-card>
</template>
<script>
  import FirmwareEdit from './FirmwareEdit.vue'
  export default {

    components: {
      FirmwareEdit
    },
    data () {
      return {
        productList: [],
      }
    },
    created () {
      this.$get('product').then((r) => {
        this.productList = r.data
      })

      setTimeout(()=>{
        this.getList()
      },200)
    },
    computed: {

      columns () {
        let tagFilters = [{ text: '全部产品', value: '' }]
        this.productList.forEach(item=>{
          tagFilters.push({ text: item.name, value: item.productKey })
        })
        return [
          {
            title: '产品',
            dataIndex: 'productKey',
            filters: tagFilters,
            filterMultiple: false,
            customRender: (text, row, index) => {
              return row.productName
            }
          },
          {
            title: '固件类型',
            dataIndex: 'type',
            filters: [
              { text: '全部类型', value: '' },
              { text: 'WIFI固件', value: '0' },
              { text: 'MCU固件', value: '1' },
            ],
            filterMultiple: false,
            scopedSlots: { customRender: 'type' }
          },
          {
            title: '上架',
            dataIndex: 'enable',
            filters: [
              { text: '全部', value: '' },
              { text: '已上架', value: '1' },
              { text: '已下架', value: '0' },
            ],
            filterMultiple: false,
            scopedSlots: { customRender: 'enable' }
          },
          {
            title: '固件名称',
            dataIndex: 'name'
          },
          {
            title: '版本',
            dataIndex: 'version'
          },
          {
            title: '更新说明',
            dataIndex: 'des'
          },
          {
            title: '创建时间',
            dataIndex: 'createdAt'
          },
          {
            title: '操作',
            dataIndex: 'operation',
            scopedSlots: { customRender: 'operation' }
          }
        ]
      }
    },
    methods: {
      add () {
        this.$refs.firmwareEdit.show(this.productList)
      },
      edit (record) {
        this.$refs.firmwareEdit.show(this.productList,record)
      },
      remove (record) {
        var that = this
        this.$confirm({
          title: '是否删除该固件?',
          content: '该操作不可撤销',
          centered: true,
          onOk () {
            that.$delete('firmware/' + record.id).then(() => {
              that.$message.success('删除成功')
              that.getList()
            })
          }
        })
      },
      async enableRecord(record){
        await this.$put('firmware', {
          ...record,
          enable:!record.enable
        })
        this.getList()
      },
      getList () {
        this.$refs.table.getData()
      },
      firmwareUpgrade(){
        this.$router.push("/device/firmware/upgrade")
      }

    }
  }
</script>
<style lang="less" scoped>


</style>
