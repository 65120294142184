<template>

  <drawer-form
    ref="drawerForm"
    :title="model.id > 0?'编辑':'添加'"
    :model="model"
    :rules="rules"
    :loading="loading"
    @handleSubmit="handleSubmit">

    <a-form-model-item label='产品' prop="productKey">
      <a-select v-model="model.productKey" >
        <a-select-option value="">请选择产品</a-select-option>
        <a-select-option v-for="product in productList" :value="product.productKey">{{product.name}}</a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item label='固件类型' prop="type">
      <a-select v-model="model.type" >
        <a-select-option value="">请选择固件类型</a-select-option>
        <a-select-option value="0">WIFI固件</a-select-option>
        <a-select-option value="1">MCU固件</a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item label='固件名称' prop="name">
      <a-input v-model="model.name" />
    </a-form-model-item>

    <a-form-item label='版本' prop="version">
      <a-input v-model="model.version" />
    </a-form-item>

    <a-form-item label='更新说明' prop="des">
      <a-textarea v-model="model.des" :rows="6" />
    </a-form-item>

    <a-form-item label='固件' prop="des">

      <div v-if="model.size>0" class="firmware-file">
        <a-icon type="file-text" />
        <div class="firmware-info">大小：{{model.size}} Byte<br/>md5：{{model.md5}}</div>

        <a-button class="firmware-action" shape="circle" icon="download" @click="downloadFirmware"/>
        <a-button class="firmware-action" type="danger" shape="circle" icon="delete" @click="deleteFirmware"/>
      </div>

      <a-upload-dragger v-else
        :multiple="false"
        :fileList="fileList"
        :remove="handleRemove"
        :disabled="fileList.length === 1"
        :beforeUpload="beforeUpload" >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">点击上传固件，或将文件拖到此处</p>
      </a-upload-dragger>
    </a-form-item>

  </drawer-form>

</template>

<script>

export default {
  name: 'FirmwareEdit',
  data () {
    return {
      isShow: false,
      file:null,
      fileList:[],
      productList:[],
      model: {
        // id: null,
        // name: null,
        // type: "",
        // version: null,
        // productKey: "",
        // link:null,
        // des: null,
        // enable: null
      },

      loading: false,
      rules: {
        name: [
          {required: true, message: '名称不能为空'},
        ],
        type: [
          {required: true, message: '请选择固件类型'},
        ],
        version: [
          { required: true, message: '版本不能为空'},
        ],
        productKey: [
          { required: true, message: '请选择产品'},
        ],
        des: [],
      },
    }
  },
  methods: {
    show (productList,model={}) {
      this.isShow = true
      // if(model){
      //   this.model = JSON.parse(JSON.stringify(model))
      //   this.model.type+=""
      // }

      this.model = JSON.parse(JSON.stringify(model))
      if(this.model.id>0){
        this.model.type+=""
      }

      this.fileList=[]
      this.productList = productList

      this.$refs.drawerForm.show()
    },
    hide () {
      this.$refs.drawerForm.hide()
    },

    handleRemove (file) {
      this.fileList = []
    },
    beforeUpload (file) {
      // this.fileList = [...this.fileList, file]

      const formData = new FormData()
      formData.append('file', file)
      this.loading = true
      this.$upload('firmware/upload', formData).then((r) => {
        this.loading = false
        this.model.link = r.data.url
        this.model.size = r.data.size
        this.model.md5 = r.data.md5
      }).catch(err=>{
        this.loading = false
      })

      return false
    },


    handleSubmit () {

      if(!this.model.productKey){
        //必须选择产品
        this.$message.error("请选择择产品")
        return
      }

      if(this.model.size == 0 ){
        this.$message.error("请上传固件")
        return
      }

      if (this.model.id > 0) {
        this.editFirmware()
      } else {
        this.addFirmware()
      }
    },

    addFirmware () {
      this.loading = true
      this.$post('firmware', {
        ...this.model
      }).then(r => {
        this.loading = false
        this.$emit('success')
        this.hide()
      }).catch(err => {
        this.loading = false
      })
    },
    editFirmware () {
      this.loading = true
      this.$put('firmware', {
        ...this.model
      }).then(r => {
        this.loading = false
        this.$emit('success')
        this.hide()
      }).catch(err => {
        this.loading = false
      })
    },

    downloadFirmware(){
      window.open(this.model.link)
    },

    deleteFirmware(){
      this.model = {
        ...this.model,
        size:0,
        md5:"",
        link:""
      }
    }

  },

}
</script>

<style scoped>
.firmware-file{
  display: flex;
  align-items: center;
  border: 1px solid #DDD;
  padding: 10px;
  border-radius: 2px;
}
  .firmware-info{
    flex-grow: 1;
    line-height: 20px;
    margin-left: 10px;
  }

  .firmware-action{
    margin-left: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

</style>
